<template>
    <p class="mb-2 pl-1 text-left"><span class="font-weight-bold">Tamanho: </span>{{ gr1desc | capitalize }}</p>
</template>

<script>
export default {
    props:{
        gr1desc:{
            type: String,
            default: ""
        }
    }
};
</script>

<style>
</style>