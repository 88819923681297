<template>
    <v-card tile color="#FDFDFD">
        <v-subheader class="font-weight-bold text-h7"
            >DESCRIÇÃO DO PRODUTO</v-subheader
        >
        <v-divider class="mx-4"></v-divider>
        <el-tiptap
            v-model="pr0ficha"
            :extensions="extensions"
            :readonly="true"
            :showMenubar="false"
            :contenteditable="false"
            :editable="false"
        >
            <template slot="footer"><div></div></template>
        </el-tiptap>
    </v-card>
</template>

<script>
import {
    // necessary extensions
    Doc,
    Text,
    Paragraph,
    Heading,
    Bold,
    Underline,
    Italic,
    Strike,
    Code,
    FontType,
    FontSize,
    TextColor,
    TextHighlight,
    FormatClear,
    Link,
    Image,
    Blockquote,
    ListItem,
    OrderedList,
    TodoItem,
    TodoList,
    TextAlign,
    Indent,
    HardBreak,
    HorizontalRule,
    CodeView,
    Fullscreen,
    History,
} from "element-tiptap";

import codemirror from "codemirror";
import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/mode/xml/xml.js"; // language
import "codemirror/addon/selection/active-line.js"; // require active-line.js
import "codemirror/addon/edit/closetag.js"; // autoCloseTags

export default {
    data() {
        return {
            extensions: [
                new Doc(),
                new Text(),
                new Paragraph(),
                new Heading({ level: 5 }),
                new Bold(),
                new Underline(),
                new Italic(),
                new Strike(),
                new Code(),
                /* new Link(), */
                /* new Image(), */
                new Blockquote(),
                new FontType(),
                new FontSize(),
                new TextColor(),
                new TextHighlight(),
                new FormatClear(),
                new TextAlign(),
                new ListItem(),
                new OrderedList(),
                new TodoItem(),
                new TodoList(),
                new Indent(),
                new HardBreak(),
                new HorizontalRule(),
                new CodeView({
                    codemirror,
                    codemirrorOptions: {
                        styleActiveLine: true,
                        autoCloseTags: true,
                    },
                }),
                new Fullscreen(),
                new History(),
            ],
        };
    },
    props: {
        pr0ficha: {
            type: String,
            default: "",
        },
    },
};
</script>

<style>
</style>