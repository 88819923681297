<template>
    <v-row class="pb-10">
        <v-col cols="12">
            <v-subheader class="font-weight-bold text-h6 justify-center"
                >PRODUTOS RELACIONADOS</v-subheader
            >
        </v-col>
        <v-col cols="12"><v-divider></v-divider></v-col>
        <v-col cols="12" class="pa-0">
            
            <SlideProducts :products="relatedProducts" />
        </v-col>
    </v-row>
</template>

<script>
const SlideProducts = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/template/SlideProducts.vue"
    );

export default {
    props:{
        relatedProducts:{
            type: Array,
            required: true
        }
    },
    components:{
        SlideProducts
    }
};
</script>

<style>
</style>