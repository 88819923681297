<template>
    <v-item-group
        v-model="product.selectedColor"
        class="d-flex align-start flex-wrap pb-6"
    >
        <v-item
            v-slot="{ active, toggle }"
            v-for="color in pr2List"
            :key="color.pr2opcao"
        >
            <v-card
                color="white"
                class="d-flex justify-center align-center mx-1 my-1"
                :elevation="active ? 6 : 0"
                :style="
                    active
                        ? `border: ${parameters.primaryColor} 2px solid !important`
                        : 'border: #dcdcdc 1px solid !important'
                "
                dark
                max-width="40"
                height="40"
                width="40"
                @click="toggle"
            >
                <v-img 
                    v-if="color.cr1imagem"
                    :src="color.cr1imagem"
                    aspect-ratio="1"
                    contain
                    v-on:error="onImgError(color)"
                />
                <v-card
                    v-else
                    dark
                    :color="color.hexadecimal"
                    height="32"
                    width="32"
                    elevation="0"
                >
                </v-card>
            </v-card>
        </v-item>
    </v-item-group>
</template>

<script>
export default {
    props:{
        pr2List:{
            type: Array,
            required: true
        }
    },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
        pr0: {
            get() {
                return this.$store.state.product.product;
            },
        },
        selectedColor: {
            get() {
                return this.$store.state.product.selectedColor;
            },
        },
        userLogged(){
            let token = localStorage.getItem("token")
            let cf1cliente = localStorage.getItem("cf1cliente")
            let rule = localStorage.getItem("rule")
            return token && cf1cliente && rule
        }
    },
    watch:{
        selectedColor(val){
            this.chooseColor(this.pr2List[val])
        },
    },
    methods:{
        async chooseColor(pr2){
            this.$store.dispatch("product/chooseColor", pr2)
            this.$emit("chooseColor", pr2)
            
            this.product.selectedSize = null
            
            await this.$store.dispatch("product/findStockByProduct")

            if(!pr2){
                this.pr0.cr1nome = ""
                this.product.stock = 0,
                this.product.lstStock = []
            }

            for (const pr3 of this.pr0.pr3List) {
                if(this.userLogged && this.pr0 && this.pr0.pr2opcao && this.parameters.store.b2bpronta && !this.hasStock(pr3.pr3tamanho))
                    pr3.color = "grey lighten-1"
                else
                    pr3.color = "white"
            }
        },
        onImgError(color){
            color.cr1imagem = ""
        },
        hasStock(pr3tamanho){
            if(this.getStock(pr3tamanho, this.pr0.pr2opcao) > 0)
                return true
            return false
        },
        getStock(pr3tamanho, pr2opcao){
            const filterStock = p => p.tamanho == pr3tamanho && p.opcao == pr2opcao
            let stock = this.product.lstStock.filter(filterStock)[0]
            if (stock)
                return stock.quantidade
            return 0
        },
    }
};
</script>

<style>
</style>