<template>
    <v-container class="pb-10" fluid>
        <v-row class="pt-5">
            <div class="col-md-6 col-sm-6 col-xs-12">
                <v-carousel height="450" v-model="selectedImage" hide-delimiter-background hide-delimiters>
                    <v-carousel-item
                        v-for="(image, i) in pr0.lstB2i"
                        :key="i"
                        :src="image.b2icaminho"
                        class="vCarouselItem"
                    >
                    </v-carousel-item>
                </v-carousel>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="pl-6">
                    <p class="pt-4 text-h6 font-weight-bold mb-0 text-left">{{ pr0.pr0desc }}</p>
                    <p class="caption text-left">Referência {{ pr0.pr2opcao && pr0.pr3tamanho ? `${pr0.pr0produto}-${pr0.pr2opcao}-${pr0.pr3tamanho}` : pr0.pr0produto }}</p>
                    <v-card-actions class="pa-0">
                        <p v-if="login.userLogged" class="text-h4 font-weight-bold pt-3" :style="{color: parameters.primaryColor}">{{ pr0.pr0preco | money}}</p>
                        <v-alert v-else outlined :color="parameters.secondaryColor">
                            <div>Realize o <a @click="$router.push('/login')" class="text-decoration-underline">login</a> para visualizar o preço do produto</div>
                        </v-alert>
                        <v-spacer></v-spacer>
                        <!-- <v-rating v-model="rating" class="" background-color="warning lighten-3"
                                    color="warning" dense></v-rating>
                        <span class="body-2	font-weight-thin"> 25 REVIEWS</span> -->
                    </v-card-actions>
                    <ProductColorTitle :cr1nome="pr0.cr1nome"/>
                    <ProductColorSelector :pr2List="pr0.pr2List" @chooseColor="chooseColor"/>
                    
                    <v-divider class="pb-6"></v-divider>
                    <ProductSizeTitle :gr1desc="pr0.gr1desc"/>
                    <ProductSizeSelector :pr3List="pr0.pr3List"/>
                    <v-card-text class="py-0 px-2" v-if="parameters.store.b2bpronta && !!pr0.pr2opcao && !!pr0.pr3tamanho">
                         <v-skeleton-loader
                            v-if="product.loadingStock"
                            type="button"
                            class="v-skeleton-loader"
                        ></v-skeleton-loader>
                        <template v-else>
                            <v-alert
                                v-if="showStock"
                                color="secondary"
                                dense
                                text
                                class="mb-0"
                            >Disponível <span v-if="parameters.store.b2bestoque && product.stock && userLogged">({{product.stock}})</span>
                            </v-alert>
                            <v-alert
                                v-else
                                color="grey darken-1"
                                dense
                                text
                                class="mb-0"
                            >Indisponível</v-alert>
                        </template>
                    </v-card-text>
                    <v-card-actions>
                        <ProductBottomSheetVd3List/>
                    </v-card-actions>
                </div>
            </div>
        </v-row>
        <div class="row">
            <div class="col-sm-12 col-xs-12 col-md-12">
                <ProductDescription :pr0ficha="pr0.pr0ficha"/>
            </div>
        </div>
        <v-divider class="pb-10"></v-divider>
        <RelatedProducts :relatedProducts="pr0.relatedProducts"/>
    </v-container>
</template>

<script>
const ProductColorTitle = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductColorTitle.vue"
    );
const ProductColorSelector = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductColorSelector.vue"
    );
const ProductSizeTitle = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductSizeTitle.vue"
    );
const ProductSizeSelector = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductSizeSelector.vue"
    );
const ProductDescription = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductDescription.vue"
    );
const RelatedProducts = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/template/RelatedProducts.vue"
    );
const ProductBottomSheetVd3List = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/details/ProductBottomSheetVd3List.vue"
    );

export default {
    data: () => ({
        selectedImage: 0
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        pr0: {
            get() {
                return this.$store.state.product.product;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
        showStock(){
            if(this.pr0.pr2opcao && this.pr0.pr3tamanho && this.userLogged)
                return this.product.stock > 0
            return this.pr0.hasStock
        },
        userLogged(){
            let token = localStorage.getItem("token")
            let cf1cliente = localStorage.getItem("cf1cliente")
            let rule = localStorage.getItem("rule")
            return token && cf1cliente && rule
        }
    },
    components:{
        ProductColorTitle,
        ProductColorSelector,
        ProductSizeTitle,
        ProductSizeSelector,
        ProductDescription,
        RelatedProducts,
        ProductBottomSheetVd3List,
    },
    methods:{
        chooseColor(pr2){
            if(!pr2){
                this.selectedImage = 0
                return
            }
            const filterImage = b2i => b2i.b2iopcao == pr2.pr2opcao
            let b2i = this.pr0.lstB2i.filter(filterImage)[0]
            if(b2i){
                this.selectedImage = b2i.b2icodigo
            }else{
                this.selectedImage = 0
            }
        }
    }
};
</script>

<style scoped>
    .vCarouselItem >>> .v-image__image{
        background-size: contain !important;
    }

    .v-skeleton-loader >>> .v-skeleton-loader__button{
        width: 100% !important;
        height: 38px !important;
    }
</style>