<template>
    <v-bottom-sheet v-model="product.sheet">
        <template v-slot:activator="{  attrs }">
            <v-bottom-navigation class="pa-0 v-bottom-navigation-oryx" :fixed="fixed">
                <v-btn
                    block
                    :color="parameters.secondaryColor"
                    tile
                    v-bind="attrs"
                    :disabled="userLogged && parameters.store.b2bestoque && disableBuy"
                    class="white--text text-button"
                    style="color: white !important"
                    @click="addToCart"
                    :elevation="0"
                >
                    Comprar
                </v-btn>
            </v-bottom-navigation>
        </template>
        <ProductVd3List />
    </v-bottom-sheet>
</template>

<script>
const ProductVd3List = () =>
    import(
        /* webpackMode: "lazy" */ /* webpackPrefetch: true */ "@/views/product/ProductVd3List.vue"
    );
import { mapGetters } from 'vuex'

export default {
    props:{
        fixed:{
            type: Boolean,
            default: false
        }
    },
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        pr0: {
            get() {
                return this.$store.state.product.product;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
        disableBuy(){
            return !this.pr0.pr2opcao || !this.pr0.pr3tamanho
        },
        login: {
            get() {
                return this.$store.state.login;
            },
        },
        userLogged(){
            let token = localStorage.getItem("token")
            let cf1cliente = localStorage.getItem("cf1cliente")
            let rule = localStorage.getItem("rule")
            return token && cf1cliente && rule
        },
    },
    components:{
        ProductVd3List,
    },
    methods:{
        addToCart(){
            if (this.disableBuy) {
                return
            }
            let vd3foto = !!this.pr0.lstB2i[0] ? !!this.pr0.lstB2i[0].b2icaminho : ""
            const filterByB2iopcao = b2i => b2i.b2iopcao == this.pr0.pr2opcao
            let b2i = this.pr0.lstB2i.filter(filterByB2iopcao)[0]
            if(!!b2i && Object.keys(b2i).length > 0 && b2i.constructor === Object)
                vd3foto = b2i.b2icaminho

            let vd3 = {
                "vd2produto": this.pr0.pr0produto,
                "vd3opcao": this.pr0.pr2opcao,
                "vd3tamanho": this.pr0.pr3tamanho,
                "vd3qtde": 1,
                "pr0etiq": this.pr0.pr0etiq,
                "vd5preco": this.pr0.pr0preco,
                "cr1nome": this.pr0.cr1nome,
                "hexadecimal": this.pr0.pr2List.filter(pr2 => pr2.pr2opcao == this.pr0.pr2opcao)[0].hexadecimal,
                "cr1imagem": this.pr0.pr2List.filter(pr2 => pr2.pr2opcao == this.pr0.pr2opcao)[0].cr1imagem,
                "gr1desc": this.pr0.gr1desc,
                "vd3foto": !vd3foto ? "../../../public/img/accountDefault.png" : vd3foto,
                "vd3desc": this.pr0.pr0desc
            }
            if (this.userLogged) {
                this.$store.dispatch("product/addVd3", vd3)
                this.product.sheet = true
                return
            }
            this.$router.replace("/login")
        }
    }
};
</script>

<style>
</style>