<template>
     <v-main>
        <v-container v-if="product.loadingProduct">
            <v-skeleton-loader
                v-bind="attrs"
                height="100%"
                width="100%"
                type="list-item-avatar-three-line, image, article, actions"
                elevation="0"
            ></v-skeleton-loader>
        </v-container>
        <template v-else>
                <ProductMobile v-if="$mq != 'lg'"/>
                <ProductDesktop v-else/>
        </template>
    </v-main>
</template>

<script>
const ProductDesktop = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/ProductDesktop.vue"
    );
const ProductMobile = () =>
    import(
        /* webpackMode: "eager" */ /* webpackPrefetch: true */ "@/views/product/ProductMobile.vue"
    );

export default {
    data: () => ({
        attrs: {
            class: 'mb-6',
            tile: true,
            elevation: 2,
        },
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        product: {
            get() {
                return this.$store.state.product;
            },
        },
    },
    components:{
        ProductDesktop,
        ProductMobile,
    },
    beforeRouteUpdate(to, from, next){
        this.$store.dispatch("product/find", this.$route.params.pr0produto)
        next()
    },
    mounted(){
        this.$store.dispatch("product/find", this.$route.params.pr0produto)
    }
}
</script>

<style>

</style>