<template>
    <p class="mb-2 pl-1 text-left">
        <span class="font-weight-bold">Cor: </span>{{ cr1nome | capitalize }}
    </p>
</template>

<script>
export default {
    props:{
        cr1nome:{
            type: String,
            default: ""
        }
    }
};
</script>

<style>
</style>